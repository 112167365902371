import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";
import AuthLayout from "layouts/auth-layout";
import AdminLayout from "layouts/admin-layout";
import ManagerLayout from "layouts/manager-layout";
import DealerLayout from "layouts/dealer-layout";
import VendorLayout from "layouts/vendor-layout";
import VendorNonAnggotaLayout from "layouts/vendor-non-anggota-layout"
import AdminStockLayout from "layouts/admin-stock-layout";
import KasirLayout from "layouts/kasir-layout";
import HrdLayout from "layouts/hrd-layout";

import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import {
  APP_PREFIX_PATH,
  AUTH_PREFIX_PATH,
  ADMIN_PREFIX_PATH,
  MANAGER_PREFIX_PATH,
  DEALER_PREFIX_PATH,
  VENDOR_PREFIX_PATH,
  KASIR_PREFIX_PATH,
  HRD_PREFIX_PATH,
  KITCHEN_PREFIX_PATH,
  VENDOR_NON_ANGGOTA_PREFIX_PATH,
  ADMIN_STOCK_NON_ANGGOTA_PREFIX_PATH
} from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";
import axios from "axios";
import { env } from "configs/EnvironmentConfig";
import { useDispatch } from "react-redux";
import { setConnectionError } from "redux/actions/site.action";
import { handleGet } from "redux/actions/baseAction";
import KitchenLayout from "layouts/kitchen-layout";
import { handleEmpty } from "helpers/general_helper";
import { message, Spin } from "antd";
import { useHistory } from "react-router-dom";
import navigationCafe from "../configs/NavigationAdminCafeConfig";
import navigationKitchen from "../configs/NavigationKitcherConfig";
import navigationHrd from "../configs/NavigationHrdConfig";
import navigationUser from "../configs/NavigationConfig";
import navigationAdmin1 from "../configs/NavigationAdminConfig";
import navigationDealer from "../configs/NavigationDealerConfig";
import navigationStnk from "../configs/NavigationAdminSTNKConfig";
import navigationVendor from "../configs/NavigationVendorConfig";
import navigationKasir from "../configs/NavigationKasirConfig";
import jwt from "jwt-decode"; // import dependency
import jwtDecode from "jwt-decode";
import { isMobile, isAndroid, isTablet } from "react-device-detect";

export const Views = (props) => {
  let history = useHistory();

  const [user, setUser] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [manager, setManager] = useState(false);
  const [dealer, setDealer] = useState(false);
  const [vendor, setVendor] = useState(false);
  const [vendorNonAnggota, setVendorNonAnggota] = useState(false);
  const [adminStock, setAdminStock] = useState(false);
  const [hrd, setHrd] = useState(false);
  const [kitchen, setKitchen] = useState(false);
  const [cashier, setCashier] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    let tokenStorage = localStorage.token;
    if (!isMobile) {
      let isHaveTokenUrl=history.location.pathname.includes("login")||history.location.pathname.includes("forgot")||history.location.pathname.includes("register");
      if (isHaveTokenUrl) {
        localStorage.removeItem("role");
        localStorage.removeItem("token");
      }
      if (tokenStorage === null || tokenStorage === undefined) {
        if (isHaveTokenUrl) {
        }else{
          history.push("/auth/login");
          localStorage.removeItem("role");
          localStorage.removeItem("token");
        }
      }
    }

    if (localStorage.getItem("token")) {
      dispatch(setConnectionError(false));
      axios
        .get(env.API + "/user/auth/user", {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        })
        .then((res) => {
          const type = res.data.data.role;
          if (type === "USER") {
            setAdmin(false);
            setUser(true);
            setManager(false);
            setDealer(false);
            setVendor(false);
            setVendorNonAnggota(false)
            setAdminStock(false)
            setHrd(false);
            setKitchen(false);
            setCashier(false);
            localStorage.setItem("is_ctu", res.data.data.is_ctu);
          }
          else if (type === "MANAGER") {
            setAdmin(false);
            setUser(false);
            setManager(true);
            setDealer(false);
            setVendor(false);
            setVendorNonAnggota(false)
            setAdminStock(false)
            setHrd(false);
            setKitchen(false);
            setCashier(false);
          }
          else if (type === "DEALER") {
            setAdmin(false);
            setUser(false);
            setManager(false);
            setDealer(true);
            setVendor(false);
            setVendorNonAnggota(false)
            setAdminStock(false)
            setHrd(false);
            setKitchen(false);
            setCashier(false);
          }
          else if (type === "POS_VENDOR") {
            setAdmin(false);
            setUser(false);
            setManager(false);
            setDealer(false);
            setVendor(true);
            setVendorNonAnggota(false)
            setAdminStock(false)
            setHrd(false);
            setKitchen(false);
            setCashier(false);
          }
          else if (type === "SPK") {
            setAdmin(false);
            setUser(false);
            setManager(false);
            setDealer(false);
            setVendor(false);
            setVendorNonAnggota(true)
            setHrd(false);
            setKitchen(false);
            setCashier(false);
          }
          else if (type === "ADMIN_STOCK") {
            setAdmin(false);
            setUser(false);
            setManager(false);
            setDealer(false);
            setVendor(false);
            setVendorNonAnggota(false)
            setAdminStock(true)
            setHrd(false);
            setKitchen(false);
            setCashier(false);
          }
          else if (type === "HR") {
            setAdmin(false);
            setUser(false);
            setManager(false);
            setDealer(false);
            setVendor(false);
            setVendorNonAnggota(false)
            setAdminStock(false)
            setHrd(true);
            setKitchen(false);
            setCashier(false);
          }
          else if (type === "POS_CASHIER") {
            setAdmin(false);
            setUser(false);
            setManager(false);
            setDealer(false);
            setVendor(false);
            setVendorNonAnggota(false)
            setAdminStock(false)
            setHrd(false);
            setKitchen(false);
            setCashier(true);
          }
          else if (type === "POS_KITCHEN") {
            setAdmin(false);
            setUser(false);
            setManager(false);
            setDealer(false);
            setVendor(false);
            setVendorNonAnggota(false)
            setAdminStock(false)
            setHrd(false);
            setKitchen(true);
            setCashier(false);
          }
          else {
            setAdmin(false);
            setUser(false);
            setManager(false);
            setDealer(false);
            setVendor(false);
            setVendorNonAnggota(false)
            setAdminStock(false)
            setHrd(false);
            setCashier(false);
          }
          localStorage.setItem("name", res.data.data.name);
          localStorage.setItem("role", res.data.data.role);
        })
        .catch((e) => {
          axios
            .get(env.API + "/admin/auth/user", {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
            })
            .then((res) => {
              setUser(false);
              setAdmin(true);
              setManager(false);
              setDealer(false);
              setHrd(false);
              setKitchen(false);
              setCashier(false);
              localStorage.setItem("name", res.data.data.name);
              localStorage.setItem("role", res.data.data.role.name);
            })
            .catch((e) => {
              if (e.response === undefined) {
                dispatch(setConnectionError(true));
              } else {
                dispatch(setConnectionError(false));
              }
              axios
                .post(
                  env.API + "/user/auth/login",
                  {},
                  {
                    headers: {
                      "Content-Type": "application/json",
                    },
                  }
                )
                .then((newRes) => {
                  axios.defaults.headers.common["Authorization"] = newRes.data.token;
                  localStorage.setItem("token", newRes.data.token);
                })
                .catch(() => {
                  if (localStorage.role !== "POS_CASHIER") {
                    localStorage.removeItem("token");
                    window.location.href = "/auth/login";
                  }
                });
            });
        });
    }
    else {
      if (localStorage.role === "POS_CASHIER") {
        axios
          .post(
            env.API + "/user/auth/login",
            {
              email: localStorage.emailUser,
              password: localStorage.passwordUser,
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((newRes) => {
            axios.defaults.headers.common["Authorization"] =
              newRes.data.data.token;
            localStorage.setItem("token", newRes.data.data.token);
            window.location.reload();
          });
      }
    }
  },[]);
  const { locale, location, direction } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);


  return (
    <IntlProvider locale="id" messages={currentAppLocale.messages}>
      <ConfigProvider locale="id" direction={direction}>
        <Switch>
          <Route path={AUTH_PREFIX_PATH}>
            <AuthLayout direction={direction} />
          </Route>
          <Route exact path="/">
            <Redirect to={AUTH_PREFIX_PATH} />
          </Route>
          {/* user */}
          {user && (
            <Route exact path="/">
              <Redirect to={APP_PREFIX_PATH} />
            </Route>
          )}

          {user && (
            <Route path={APP_PREFIX_PATH}>
              <AppLayout direction={direction} location={location} />
            </Route>
          )}

          {user && (
            <Route path={MANAGER_PREFIX_PATH}>
              <Redirect to={APP_PREFIX_PATH} />
            </Route>
          )}

          {user && (
            <Route path={DEALER_PREFIX_PATH}>
              <Redirect to={APP_PREFIX_PATH} />
            </Route>
          )}

          {user && (
            <Route path={ADMIN_PREFIX_PATH}>
              <Redirect to={APP_PREFIX_PATH} />
            </Route>
          )}

          {/* manager */}
          {manager && (
            <Route exact path="/">
              <Redirect to={MANAGER_PREFIX_PATH} />
            </Route>
          )}

          {manager && (
            <Route path={APP_PREFIX_PATH}>
              <Redirect to={MANAGER_PREFIX_PATH} />
            </Route>
          )}

          {manager && (
            <Route path={MANAGER_PREFIX_PATH}>
              <ManagerLayout direction={direction} location={location} />
            </Route>
          )}

          {manager && (
            <Route path={DEALER_PREFIX_PATH}>
              <Redirect to={MANAGER_PREFIX_PATH} />
            </Route>
          )}

          {manager && (
            <Route path={ADMIN_PREFIX_PATH}>
              <Redirect to={APP_PREFIX_PATH} />
            </Route>
          )}

          {/* dealer */}

          {dealer && (
            <Route exact path="/">
              <Redirect to={DEALER_PREFIX_PATH} />
            </Route>
          )}

          {dealer && (
            <Route path={APP_PREFIX_PATH}>
              <Redirect to={DEALER_PREFIX_PATH} />
            </Route>
          )}

          {dealer && (
            <Route path={MANAGER_PREFIX_PATH}>
              <Redirect to={DEALER_PREFIX_PATH} />
            </Route>
          )}

          {dealer && (
            <Route path={DEALER_PREFIX_PATH}>
              <DealerLayout direction={direction} location={location} />
            </Route>
          )}

          {dealer && (
            <Route path={ADMIN_PREFIX_PATH}>
              <Redirect to={APP_PREFIX_PATH} />
            </Route>
          )}

          {/* admin */}
          {admin && (
            <Route exact path="/">
              <Redirect to={ADMIN_PREFIX_PATH} />
            </Route>
          )}

          {admin && (
            <Route path={APP_PREFIX_PATH}>
              <Redirect to={ADMIN_PREFIX_PATH} />
            </Route>
          )}

          {admin && (
            <Route path={MANAGER_PREFIX_PATH}>
              <Redirect to={ADMIN_PREFIX_PATH} />
            </Route>
          )}

          {admin && (
            <Route path={DEALER_PREFIX_PATH}>
              <Redirect to={ADMIN_PREFIX_PATH} />
            </Route>
          )}

          {admin && (
            <Route path={ADMIN_PREFIX_PATH}>
              <AdminLayout direction={direction} location={location} />
            </Route>
          )}
          {vendor && (
            <Route path={VENDOR_PREFIX_PATH}>
              <VendorLayout direction={direction} location={location} />
            </Route>
          )}
          {vendorNonAnggota && (
            <Route path={VENDOR_NON_ANGGOTA_PREFIX_PATH}>
              <VendorNonAnggotaLayout direction={direction} location={location} />
            </Route>
          )}
           {adminStock && (
            <Route path={ADMIN_STOCK_NON_ANGGOTA_PREFIX_PATH}>
              <AdminStockLayout direction={direction} location={location} />
            </Route>
          )}
          {hrd && (
            <Route path={HRD_PREFIX_PATH}>
              <HrdLayout direction={direction} location={location} />
            </Route>
          )}
          {kitchen && (
            <Route path={KITCHEN_PREFIX_PATH}>
              <KitchenLayout direction={direction} location={location} />
            </Route>
          )}

          {cashier && (
            <Route path={KASIR_PREFIX_PATH}>
              <KasirLayout direction={direction} location={location} />
            </Route>
          )}
        </Switch>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, token, direction };
};

export default withRouter(connect(mapStateToProps)(Views));
