import {
  SettingOutlined,
  UnorderedListOutlined,
  UserOutlined,
  
} from "@ant-design/icons";
import { ADMIN_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "akun-admin-material",
    path: `${ADMIN_PREFIX_PATH}/akun-admin-material`,
    title: "Akun Admin",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "data-karyawan-material",
    path: `${ADMIN_PREFIX_PATH}/data-karyawan-material`,
    title: "Data Karyawan",
    icon: UnorderedListOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "vendor-non-anggota",
    path: `${ADMIN_PREFIX_PATH}/vendor-non-anggota`,
    title: "Data Vendor",
    icon: UnorderedListOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "pengaturan-divisi-kebutuhan-posisi",
    path: `${ADMIN_PREFIX_PATH}/pengaturan-divisi-kebutuhan-posisi`,
    title: "Divisi",
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "pengaturan-anggota",
    path: `${ADMIN_PREFIX_PATH}/pengaturan-anggota`,
    title: "Pengaturan Email",
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

const navigationNonAnggotaMasterConfig = [...dashBoardNavTree];

export default navigationNonAnggotaMasterConfig;
