import React, { lazy, Suspense, useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import axios from "axios";
import { env } from "configs/EnvironmentConfig";
import navigationAdminStockNonAnggotaConfig from "configs/NavigationAdminStockNonAnggota";
export const AdminStockViews = () => {
  const [stnk, setStnk] = useState(false);
  const getData = () => {
    axios
      .get(env.API + "/user/auth/user", {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      })
      .then((res) => {
        const data = res.data.data.role.permissions;
        data.forEach((d) => {
          if (d == "MANAGE_STNK") {
            setStnk(true);
          }
        });
      })
      .catch((e) => {});
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        {navigationAdminStockNonAnggotaConfig.map((res, i) => {
          return (
            <Route
              key={i}
              path={res.path}
              component={lazy(() => import("./" + res.key + "/index"))}
            />
          );
        })}
        <Route
          path={"/admin-stock/detail_transaksi/:id"}
          component={lazy(() => import("./dashboard/detailTransaksi"))}
        />
        <Route
          path={"/admin-stock/detail/:id"}
          component={lazy(() => import("./dashboard/detail"))}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AdminStockViews);
