import {
  AppstoreOutlined,
} from "@ant-design/icons";
import { ADMIN_STOCK_NON_ANGGOTA_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "dashboard",
    path: `${ADMIN_STOCK_NON_ANGGOTA_PREFIX_PATH}/dashboard`,
    title: "Dashboard",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [],
  },
  
];

const navigationAdminStockNonAnggotaConfig = [...dashBoardNavTree];

export default navigationAdminStockNonAnggotaConfig;
